import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _91df1b90 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _646508b0 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _e9bf1a5a = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _ab2f9e2a = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _6c7524ea = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _f0363eea = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _4ccea009 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _d42a8ab8 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _5cae61a6 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _22eec364 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _ed3f4ebc = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _b239bba4 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _71205098 = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _95afe5ea = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _10fd8343 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _df1f7924 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _e7473256 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _1f0135cb = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _80f335a0 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _7e6c96e0 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _af987d16 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _3b3eaac7 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _c06d0b8a = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _09fc3b52 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _988f10d0 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _5e0ddae6 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _9e9551e2 = () => interopDefault(import('../pages/sale/output.vue' /* webpackChunkName: "pages/sale/output" */))
const _c872895a = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _7c6adcf1 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _26479417 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _813eeb64 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _2fcf2fff = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _2a7cb36a = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _2bfbfb22 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _890bc138 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _5c1f7c00 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _6401029b = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _b5ef73fa = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _3600ed4a = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _19bc0d96 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _269ba096 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _e0348efe = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _e40927c4 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _6d0d2e62 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _76289bdc = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _06662e1e = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _83da5470 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _2015ace2 = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _7e9d732c = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _fabac06c = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _bb1e345e = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _3d4801ce = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _55d08926 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _cee7bbd0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _037b8d6a = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _0428edb0 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _7d18d01a = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _45488d8c = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _21cddf59 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _51316a78 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _cf974ab0 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _7e61767b = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _0d948852 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _019b43ec = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _4594efc3 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _1bf4bac0 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _3fc30dfb = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _11f26de7 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _5b51529a = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _4cea8e56 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _5d101b8a = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _1babfc24 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _243ce910 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _01d6d234 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _92c6895c = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _1b8fd816 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _91df1b90,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _646508b0,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _e9bf1a5a,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _ab2f9e2a,
    name: "calendar"
  }, {
    path: "/customer",
    component: _6c7524ea,
    name: "customer"
  }, {
    path: "/details",
    component: _f0363eea,
    name: "details"
  }, {
    path: "/goals",
    component: _4ccea009,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _d42a8ab8,
    name: "maisoku"
  }, {
    path: "/media",
    component: _5cae61a6,
    name: "media"
  }, {
    path: "/privacy",
    component: _22eec364,
    name: "privacy"
  }, {
    path: "/project",
    component: _ed3f4ebc,
    name: "project"
  }, {
    path: "/response",
    component: _b239bba4,
    name: "response"
  }, {
    path: "/sale",
    component: _71205098,
    name: "sale"
  }, {
    path: "/achievement/output",
    component: _95afe5ea,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _10fd8343,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _df1f7924,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _e7473256,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _1f0135cb,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _80f335a0,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _7e6c96e0,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _af987d16,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _3b3eaac7,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _c06d0b8a,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _09fc3b52,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _988f10d0,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _5e0ddae6,
    name: "response-registration"
  }, {
    path: "/sale/output",
    component: _9e9551e2,
    name: "sale-output"
  }, {
    path: "/setting/announcement",
    component: _c872895a,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _7c6adcf1,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _26479417,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _813eeb64,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _2fcf2fff,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _2a7cb36a,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _2bfbfb22,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _890bc138,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _5c1f7c00,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _6401029b,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _b5ef73fa,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _3600ed4a,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _19bc0d96,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _269ba096,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _e0348efe,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _e40927c4,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _6d0d2e62,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _76289bdc,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _06662e1e,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _83da5470,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _2015ace2,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _7e9d732c,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _fabac06c,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _bb1e345e,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _3d4801ce,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _55d08926,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _cee7bbd0,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _037b8d6a,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _0428edb0,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _7d18d01a,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _45488d8c,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _21cddf59,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _51316a78,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _cf974ab0,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _7e61767b,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _0d948852,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _019b43ec,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _4594efc3,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _1bf4bac0,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _3fc30dfb,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _11f26de7,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _5b51529a,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _4cea8e56,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _5d101b8a,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _1babfc24,
    name: "property-property",
    children: [{
      path: "contact",
      component: _243ce910,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _01d6d234,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _92c6895c,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _1b8fd816,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
